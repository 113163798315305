import { Container } from 'react-bootstrap'
import { TextField } from '@mui/material';
import React, { useEffect, useState, useRef } from "react";
import ProductBlock from "./Block/ProductBlock";
import VectorBlock from "./Vector/VectorBlock";
import "./slider/slider.css";
import axios from 'axios';
import Button from '../Button/Button';
import ProductBlockCard from './Block/ProductBlockCard';

const ThirdSectiionCard =  ({products,onProductBlockNavigate, selectedCategory, selectedLocation,modellNo,serialNo}) => {
    const [box, setBox] = useState(0);
    const sliderRef = useRef(null);
    const [error, setError] = useState('');
    let [selectedCategoryformatted, setSelectedCategoryformatted] = useState('');
    // console.log(products, 'Products List')
    console.log('Selected Category: in ThirdSection page ', selectedCategory);
    console.log('Selected Location: in ThirdSection page ', selectedLocation);
    // console.log('Selected Location in ThirdSection Page ',modellNo);
    // console.log('Selected Location in ThirdSection Page ',serialNo);


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    
    useEffect(() => {
        // Scroll to the section when the component mounts
        const element = document.getElementById('scroll-target');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, []);
    
    const btnpressprev = () => {
        let width = box.clientWidth;
        box.scrollLeft = box.scrollLeft - width;
        console.log(width)
    }

    const btnpressnext = () => {
        let width = box.clientWidth;
        box.scrollLeft = box.scrollLeft + width;
        console.log(width)
    }
    // set the selectedCategory === 'CNC Vertical machine Center (VMC)' to CNC VMC
    //fix the Assignment to constant variable. TypeError: Assignment to constant variable.
    if (selectedCategory === 'CNC Vertical machine Center (VMC)') {
        selectedCategoryformatted = 'CNC VMC';
    }
    if (selectedCategory === 'CNC Horizontal machining center') {
        selectedCategoryformatted = 'CNC HMC';
    }
    if (selectedCategory === 'CNC Turning') {
        selectedCategoryformatted = 'CNC Turning';
    }
    if (selectedCategory === 'CNC Turnmill Machine') {
        selectedCategoryformatted = 'CNC Turnmill Machine';
    }

    const heading = `PM Plans for ${selectedCategoryformatted} in ${selectedLocation}`;

    const para =
        "Enjoy the flexibility of selecting from our diverse range of plans";
    useEffect(() => {

        setBox(sliderRef.current);
    }, [])

    // const list = products;

    // Second Section Code
    const [firstname, setfirstname] = useState('');

    const onFirstnameChange = (e) => {
        setfirstname(e.target.value);
    };

    // const serialNumberCalling = (e) => {
    //     setSerialNo(e.target.value);
    // }

    // const [serialNo, setSerialNo] = useState('');
    const [list, setList] = useState(products);

    const fetchSerialNumber = () => {
        setError('');

        if (!firstname || !serialNo) {
            setError("Fill the data")
        } else {
            const graphqlQuery = {
                query: `
                query GetOwnedMachines($first: Int!, $modelno: String!,, $serialno: String!) {
                    getOwnedmachies(
                      first: $first
                      modelName: $modelno
                      serialNo: $serialno
                    ) {
                      edges {
                        node {
                          id
                          firstName
                          lastName
                          mobileNo
                          email
                          modelName
                          machineName
                          serialNo
                          customerId
                          machineType
                          requestJson
                        }
                      }
                    }
                  }
                  
                `,
                variables: {
                    "first": 100,
                    "modelno": firstname,
                    "serialno": serialNo

                }
            };
            axios.post('https://ompextension.origa.market/graphql/', graphqlQuery)
                .then(response => {
                    console.log('Seriesno annual thirdSection==>>', response.data.data.getOwnedmachies.edges)
                    if (response.data.data.getOwnedmachies.edges) {
                        products = response.data.data.getOwnedmachies.edges
                        setList(products);
                        console.log("check==>>",products);
                    } else {

                    }
                    // if (data && data.message) {
                    //     setMessage(data.message);
                    // } else {
                    //     setMessage('Sorry! we are not Serve in this area');

                    // }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
        // Define your GraphQL query  ...border-line
    };
    return (
        <>
        {/* <div className='container-fluid border hide-992'></div> */}
            {/* <div className='max-container pb-3 tablet-d-padding'>
                    <div className='form-flexs '>
                        <div className='hide-992 '>
                            <h2 className='form__heading'>Enter Machine Details</h2>
                            <p className='small_para'>Enjoy the flexibility of selecting from our diverse range of plans</p>
                        </div>
                        <div className='container-fluid p-0 m-0 row d-flex g-3 align-items-center'>
                            <div className='col col-lg-4 col-12 '>
                            <TextField className='form-width ' fullWidth label="Model" id="First-Name" size='small' value={firstname} onChange={onFirstnameChange} />
                            </div>
                           <div className='col col-lg-4 col-12 '>
                           <TextField className='form-width' fullWidth placeholder='serial number/machine number' id="First-Name" size='small' value={serialNo} onChange={serialNumberCalling} />
                           </div>

                           <div className='col col-lg-4 col-12 d-flex justify-content-end'>
                           <Button message="Submit" callFunction={fetchSerialNumber} />
                           </div>
                        </div>
                        
                    </div>
                    <div className="text-center">
                        {error && <p className="error-message">{error}</p>}
                    </div>
              
            </div> */}



            <div  className='container-fluid  hide-992  '></div>


            {/* <div className='somesgnfkjnfkjfb' id="scroll-target">.</div> */}


            {/* Our AMC Plans Start */}

            {list.length>0 &&  <Container  fluid className="">
                <div className='max-container pt-0'>
                  
                <div className='container-fluid p-0 m-0 row '  >
                   <div   className='col col-lg-8 col-12 p-0' >
                   <h1 className='heading-600-28-20 pt-3'>{heading}</h1>
                   <p className='heading-400-16-12 op-80'>{para}</p>
                   </div>

                   <div className='col col-lg-4 d-flex justify-content-end' >
                   <div className="hide-992 p-0"> <VectorBlock onMoveLeft={btnpressprev} onMoveRight={btnpressnext} /></div>
                   </div>
                   </div>
                </div>
                <Container className="sixthSectionSlider " ref={sliderRef}>
                    {
                        list.map((product, index) => (
                            <ProductBlockCard
                                id={product.id}
                                key={index}
                                title={product.name}
                                price={'On Demand'}
                                plan={product.plan}
                                imageUrl={product.plan_image}
                                product={product}
                                onProductBlockNavigate={onProductBlockNavigate}
                                totalProducts={list.length}
                                index={index}
                                selectedCategory={selectedCategory}
                                selectedLocation={selectedLocation}
                            />
                        ))
                    }
                </Container>
                <div className='show-992 h-center w-fit'><VectorBlock onMoveLeft={btnpressprev} onMoveRight={btnpressnext} /></div>
            </Container>}
        </>
    );
};


export default ThirdSectiionCard