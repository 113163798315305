import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Card from "./Card";

const CardGrid = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cards = [
    {
      //First Card
      imgSrc: "asset/Yasho card.webp",
      label: "Case Study",
      heading: "Yasho Industries Limited ",
      logoSrc:"asset/yash_logo.webp",
      text:
        "ORIGA’S innovative leasing strategy for Yasho Industries Limited and  growth initiatives.",
      slug: "origa-innovative-leasing-strategy",
    },

    //Second Card
    {
      imgSrc: "asset/Manipal card .webp",
      label: "Case Study",
      heading: "Manipal’s MCT Card and Technologies Pvt Ltd",
      logoSrc:"asset/MCT_logo.webp",
      text:
        "Manipal and Origa’s Strategic Leasing Partnership for Business Expansion",
      slug: "manipal-origa-stragetic",
    },

    // Third Card
    {
      imgSrc: "asset/externalimageofleasing5thcard.webp",
      label: "Blog",
      heading: "Seven Myths About Equipment Leasing",
      logoSrc:"asset/internalimageofleasing5thcard.webp",
      text:
        "Seven Myths About Equipment Leasing in India",
      slug: "Seven Myths About Equipment Leasing",
    },

    //Fourth Card
    {
      imgSrc: "asset/Karkinos card.webp",
      label: "Case Study",
      heading: "Karkinos Healthcare Pvt.Ltd",
      logoSrc:"asset/karkinos.webp",
      text:
        "Innovative leasing & financing solution for Karkinos Healthcare Pvt.Ltd",
      slug: "karkinos-healthcare",
    },

    //Fifth Card
    {
      imgSrc: "asset/4thcardexternalimageresourcehubcorrect.webp",
      label: "Blog",
      heading: "Winning the Best Price",
      logoSrc:"asset/karkinos.webp",
      text:
        "Winning the Best Price: A Step-by-Step Guide to Selling Used Equipment",
      slug: "Winning-the-Best-Price",
    },

    //Sixth Card
    {
      imgSrc: "asset/externalimageof6thcard.webp",
      label: "Article",
      heading: "Quality Production & Productivity",
      logoSrc:"asset/karkinos.webp",
      text:
        "Quality Production and Productivity Factors in Enhancing Shareholder Value",
      slug: "Quality-Production-and-Productivity",
    },
    

    
  ];

  

  return (
    <div className="wrapper">
      <div className="cards">
        {cards.map((card, index) => (
          <div key={index}>
            {/* <Helmet>
              <title>{card.heading}</title>
            </Helmet> */}
            
            <Card
              imgSrc={card.imgSrc}
              label={card.label}
              logoSrc={card.logoSrc}
              heading={card.heading}
              text={card.text}
              slug={card.slug}
              index={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardGrid;
