import React, { useState ,useEffect } from 'react'
import './Nav.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Avatar from '../../SubComponent/avatar/Avatar';
import Phone from '../../SubComponent/phone/Phone';
import Cart from '../../../component/SubComponent/Cart/Cart'
import Language from '../../SubComponent/language/Language';
import { useNavigate } from 'react-router-dom';

import NavDropdown from 'react-bootstrap/NavDropdown';
import LoginModel from '../../Authentication/LoginModel/LoginModel';


// <Language />

function Nav() {

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 992);
    const [cartItems,setCartItems] = useState(JSON.parse(localStorage.getItem('cartItems')) || []);
    const [loginPortal, setLoginPortal] = useState(false);
    const navigate = useNavigate();
    const uniqueProductIds = [...new Set(cartItems.map(item => item.productId))];
    const totalUniqueItems = uniqueProductIds.length;
     const [totalQuantity, setTotalQuantity] = useState(0);
    const loggedin = !!localStorage.getItem('userToken');
    const [quantity, setQuantity] = useState("");
  let draftItems = [];
   
    const onLogoHandler=()=>navigate('/')
    const onShowPortal = () => {
        const loggedin = !!localStorage.getItem('userToken');
        if (loggedin) {
            navigate('/myaccount')
            return
        }
        else {
            setLoginPortal(true);
        }
    }
    const onShowPortalforCart = () => {
        const loggedin = !!localStorage.getItem('userToken');
        if (loggedin) {
            navigate('/cartpage')
            return
        }
        else {
            setLoginPortal(true);
        }
    }

    const onHidePortal = () => {
        setLoginPortal(false);
    }
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 992);
        };

        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const [subtotal, setSubtotal] = useState(0);
    const [taxTotal, setTaxTotal] = useState(0);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [discountPercent, setDiscountPercent] = useState(0);
    const [shippingCharge, setShippingCharge] = useState(0);
    const[totalprice,setTotalPrice] = useState(0);
    const [total, setTotal] = useState(0);
    const totalItemsInCart = totalprice;

    

    const [totalCartValue, setTotalCartValue] = useState(0);

    useEffect(() => {
      const total = calculateTotalCartValue();
      setTotalCartValue(total);
    }, []);
  
    const fetchCartItems = () => {
      return JSON.parse(localStorage.getItem("cartItems")) || [];
    };
  
    const calculateTotalCartValue = () => {
      const cartItems = fetchCartItems();
      return cartItems.reduce((total, item) => total + (item.quantity), 0);
    };






    
  useEffect(() => {
    const addCartItems = async () => {
      const loggedin = !!localStorage.getItem('userToken');
      const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const ompuserId = localStorage.getItem("id") || "defaultId";
      const customer_first_name = localStorage.getItem("firstName") || "DefaultFirstName";
      const customer_last_name = localStorage.getItem("lastName") || "DefaultLastName";
      const customer_contact_no = localStorage.getItem("number") || "DefaultContactNo";
      const customer_email = localStorage.getItem("emailId") || "default@example.com";

      if (loggedin) {
        const requestBody = {
          items: draftItems.map(item => ({
            item_id: item.item_id,
            quantity: item.quantity
          })),
          ompuserId,//get the id from the localStorage
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "",
        };
        try {
          // Fetch draft items
          const draftResponse = await fetch(
            'https://ompextension.origa.market/sparesapi/create_order',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
            }
          );

          if (!draftResponse.ok) {
            throw new Error('Failed to fetch draft items');
          }

          const draftData = await draftResponse.json();
          setCartItems(draftData.line_items || []);
          setSubtotal(draftData.subtotal || 0);
          setTaxTotal(draftData.tax_total || 0);
          setDiscountTotal(draftData.discount_total || 0);
          setDiscountPercent(draftData.discount_percent || 0);
          setShippingCharge(draftData.shipping_charge || 0);
          setTotal(draftData.total || 0);
          setTotalPrice(draftData.total_quantity || 0);
          setQuantity(draftData.total_quantity || 0);

         
          const totalQuantity = draftData.line_items.reduce((acc, item) => acc + item.quantity, 0);
          setTotalQuantity(totalQuantity);
        } catch (error) {
          console.error('Error fetching or merging cart items:', error);
        }
      } else {
        setCartItems(localCartItems); // Use local cart items if not logged in
      }
    };

    addCartItems(); // Call the function to add cart items on component mount
  }, []);
      
      // Function to merge local items with draft items
      const mergeItems = (localItems, draftItems) => {
        const mergedItems = [...draftItems];
      
        localItems.forEach((localItem) => {
          const foundIndex = mergedItems.findIndex((item) => item.item_id === localItem.item_id);
          if (foundIndex !== -1) {
            mergedItems[foundIndex].quantity += localItem.quantity;
          } else {
            mergedItems.push(localItem);
          }
        });
      
        return mergedItems;
      };
    const handleProceedPaymentbtn = () => {
      const loggedin = !!localStorage.getItem('userToken');
      if (loggedin) {
        handleCreateOrder();
        navigate('/manageaddresspart');
      } else {
        setLoginPortal(true);
      }
    };
    

    const handleCreateOrder = async () => {
      const userToken = localStorage.getItem('userToken');
      if (!userToken) {
        // Handle case where user is not logged in
        setLoginPortal(true); // Assuming this sets state to show login portal
        return;
      }
      // console.log('cartItems --------------------------------------------------------------- > :', cartItems);
      // console.log("cartItems -------------------------------->  ",cartItems)
      const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      console.log("localCartItems :: ", localCartItems);
      
      var itemList=[];
      for(var item in localCartItems){
          var obj={};
          obj["item_id"] = localCartItems[item]["productId"];
          obj["quantity"] = quantity;
          console.log("quantity--------------------------> of the item id -------------->",quantity );
          itemList.push(obj);
      }
      console.log("itemList -------------------->  :: ", itemList );
  
      const ompuserId = localStorage.getItem("id") ;
      const customer_first_name = localStorage.getItem("firstName") ;
      const customer_last_name = localStorage.getItem("lastName");
      const customer_contact_no = localStorage.getItem("number");
      const customer_email = localStorage.getItem("emailId") ;
    
      
      const requestBody = {
  
        items: itemList,
        ompuserId,//get the id from the localStorage
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "",
        };
         
    
      try {
        console.log("requestBody  ------------------------------------------------------------- > :: ", requestBody);
        // Create order request
        const createOrderResponse = await fetch(
          'https://ompextension.origa.market/sparesapi/create_order',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          }
        );
    
        if (!createOrderResponse.ok) {
          throw new Error('Failed to create order');
        }
    
        const createOrderData = await createOrderResponse.json();
        const draftItems = createOrderData.line_items || []; // Assuming draft items are in line_items array
        setQuantity(draftItems.total_quantity || 0);
        console.log('Created order with items:', draftItems);
    
        // Clear local storage cartItems after successful order creation
        // localStorage.removeItem('cartItems');
        // setCartItems([]); // Clear cart items state
      } catch (error) {
        console.error('Error creating order:', error);
      }
    };
  
    return (
        <>
            {
                isSmallScreen === false ? <>
                    <div className="navcontainer">
                        <Container fluid className="logo">
                            <img className='curser-pointer' src="/asset/image 6.png" alt="image6.png" onClick={onLogoHandler}/>
                        </Container>
                        <div className="user">
                            <Row>
                                <Col className='nav-col typ-hidden-icon curser-pointer'>
                                <NavDropdown className='lang-a' title="En" >
                                <NavDropdown.Item >English</NavDropdown.Item>
                                <NavDropdown.Item >Hindi</NavDropdown.Item>
                            </NavDropdown>
                                </Col>

                                <Col className='nav-col typ-hidden-icon curser-pointer' onClick={() => navigate('/contactus')}><Phone  width="24" height="24" viewBox="0 0 24 24"/></Col>
                                <Col className='nav-col typ-cart-btn cursor-pointer' onClick={() => navigate('/cartpage')}>
                                
                                <Cart />
                                  {/* {loggedin ? (
                                  <span className="cart-badge">{totalItemsInCart}</span>
                                  ) : (
                                  <span className="cart-badge">{totalCartValue}</span>
                                  )} */}
                                  </Col>
                                {/*<Col className='nav-col'> <NavLink to="/login"><Avatar/></NavLink></Col>*/}
                                <Col className='nav-col typ-hidden-icon curser-pointer' onClick={onShowPortal}><Avatar /></Col>
                            </Row>
                        </div>
                    </div>
                    {loginPortal && <LoginModel onHide={onHidePortal} />}
                </>
                    : null
            }

        </>

    );
}

export default Nav;
