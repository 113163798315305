import { connectHits } from "react-instantsearch-dom";
import { heartIcon, botIcon } from "../../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
// import CustomPagination from "../CustomPagination";
import React, { useState, useEffect } from "react";
import useWishListAddOrUpdate from "../../../SubComponent/useWishListAddOrUpdate";
import CustomPagination from "../CustomPagination";
import { secondClient, GET_SPARE_DETAILS } from '../../../OrigaExtentionAPI/mutations'
import LoginModel from "../../../Authentication/LoginModel/LoginModel";
import Loader from "../../../SubComponent/Loader";

//----------------------------------------Showing Information Of the Product Based On Result Hits-------------------------------------------------------

const client = secondClient
const CustomHits = ({loading, setLoading, hits, Sorting,deliveryAvailable }) => {
    // setLoading(false)
    const [productDETAILS, setProductDETAILS] = useState({});
    const [quantity, setQuantity] = useState(1);
    const queryParams = new URLSearchParams();
    const [loginPortal, setLoginPortal] = useState(false);
    const productId = queryParams.get('id');//to get the id from url
    const groupId = queryParams.get('group_id');//to get the id from url
    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");
    // console.log('productId---->', productId);
    const [deliveryLocation, setDeliveryLocation] = useState("");
    const [buymachineId, setBuymachineId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [infokey, setinfokey] = useState(0);
    const productIdtoCart = productDETAILS?.[infokey]?.id;

    
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top on component mount or update
    const fetchData = async () => {
      let id = localStorage.getItem("id");
      if (id === null) {
        id = "";
      }
      try {
        // Performs a GraphQL query using the Apollo client 'client'
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { groupId: groupId || "", itemId: productId || "" },
        });
        // Sets the fetched data to state variable 'productDETAILS'
        setProductDETAILS(data?.inventoryItem?.response);
        // Sets 'loading' state to false after data is fetched
        setLoading(false);
        // Updates formatted price
        updateFormattedPrice();
      } catch (error) {}
    };
    fetchData();
  }, [groupId, productId]);
  

  const productname = productDETAILS?.[infokey]?.name;
  const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
  const productimage =productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
  const saleprice = productDETAILS?.[infokey]?.saleprice ;
//   const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
//   const productimage =
//     productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
const handleCart = async (productIdtoCart, productName, salePrice,product) => {
    // Get product details from the response
    
    
    const productDetails = Object.values(productDETAILS).find(product => product.id === productIdtoCart);


       // If product is not found, handle accordingly
       if (!productDetails) {
        console.log("Product not found in product details.");
        return;
    }


    const priceList = productDetails.price_list || [];
    console.log("Price list: ", priceList);

    if (priceList.length === 0) {
        console.log("Price list is empty for the given product ID");
        return;
    }


    // Retrieve the first item from the price list
    if (priceList.length > 0) {
        const firstPriceInfo = priceList[0];
        // Store the first bucket ID, bucket size, and price in variables
        const bucketId = firstPriceInfo.bucket_id;
        const bucketSize = firstPriceInfo.bucket_size;
        const firstPrice = firstPriceInfo.price;

        console.log("First bucket ID:", bucketId);
        console.log("First bucket size:", bucketSize);
        console.log("First price:", firstPrice);

        // For now, set quantity to 1 as per the requirement
        const quantity = 1;
        const quantitypricevc = quantity; // Same quantity

        // Calculate total cart price
        const totalCartPriceof = firstPrice * bucketSize * quantitypricevc;
        console.log("Calculated total cart price:", totalCartPriceof);

        // Get user details from localStorage
        const loggedin = localStorage.getItem("userToken");
        let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        console.log("Before - Cart Items:", cartItems);

        const ompuserId = localStorage.getItem("id");
        const customer_first_name = localStorage.getItem("firstName");
        const customer_last_name = localStorage.getItem("lastName");
        const customer_contact_no = localStorage.getItem("number");
        const customer_email = localStorage.getItem("emailId");

        // Assign other product details
        const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
        const productimage = productDETAILS?.[infokey]?.images?.productImages[0]?.url || "N/A";

        // Check if the product is already in the cart
        const isProductInCart = cartItems.some((item) => item.productId === productIdtoCart);

        if (!isProductInCart) {
            // Add product to cart if not already present
            cartItems.push({
                productId: productIdtoCart,
                productName: productName,
                productBrand: productbrand,
                productImage: productimage,
                salePrice: firstPrice, // Use the price from price list
                quantity: quantitypricevc,
                bucketId: bucketId,
                bucketSize: bucketSize,
                bucketPrice: firstPrice,
                totalCartPriceocvf: totalCartPriceof,
                endQuantity: firstPriceInfo.end_quantity
            });

            console.log("After - Cart Items:", cartItems);
        }

        if (loggedin) {
            // If logged in, send the item to the API
            try {
                let itemList = cartItems.map(item => ({
                    item_id: item.productId,
                    quantity: item.quantity,
                    bucket_id: item.bucketId
                }));

                console.log("Sending itemList to API:", itemList);

                await fetch("https://ompextension.origa.market/sparesapi/create_order", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        items: itemList,
                        bucket_id: bucketId,
                        ompuserId,
                        customer_first_name,
                        customer_last_name,
                        customer_contact_no,
                        order_status: "Draft",
                        order_ref_number: "",
                        customer_email,
                        input: {
                            city: "",
                            cityArea: "",
                            companyName: "",
                            country: "",
                            countryArea: "",
                            firstName: "",
                            lastName: "",
                            phone: "",
                            postalCode: "",
                            streetAddress1: "",
                            streetAddress2: "",
                        },
                        businessaddress: {
                            city: "",
                            cityArea: "",
                            companyName: "",
                            country: "",
                            countryArea: "",
                            firstName: "",
                            lastName: "",
                            phone: "",
                            postalCode: "",
                            streetAddress1: "",
                            streetAddress2: "",
                        },
                        gst_no: "",
                    }),
                });
                console.log(`Item added to API cart. Product ID: ${productIdtoCart}`);
            } catch (error) {
                console.error("Error adding item to API cart:", error);
            }
        } else {
            // If not logged in, save to localStorage
            localStorage.setItem("cartItems", JSON.stringify(cartItems));
        }

        // setLoading(true);

        // setTimeout(() => {
        //     navigate("/cartpage");
        // }, 500);




        setLoading(true);
        setTimeout(() => {
          window.location.reload();
          // navigate("/cartpage");
        }, 400);

        //   navigate(`/buy/sparetools`, { state });
         
          
    } else {
        console.error("Price list is empty or not available");
    }
};

<button
    className="purchasef pbtnsb"
    onClick={() => handleCart()}
    type="button"
>
    Add to Cart
</button>

const handleBuyNow = async (productIdtoCart, quantity, productName, salePrice) => {
    // Log the details of the clicked product
    console.log("Product ID:", productIdtoCart);
    console.log("Quantity:", quantity);
    console.log("Product Name:", productName);
    console.log("Sale Price:", salePrice);
    
     // Retrieve the price list for the product with the given ID
      // Find the product details by ID
    const productDetails = Object.values(productDETAILS).find(product => product.id === productIdtoCart);

    // If product is not found, handle accordingly
    if (!productDetails) {
        console.log("Product not found in product details.");
        return;
    }

    // Retrieve the price list for the specific product
    const priceList = productDetails.price_list || [];
    console.log("Price list: ", priceList);

    if (priceList.length === 0) {
        console.log("Price list is empty for the given product ID");
        return;
    }

    // Declare bucket-related variables outside the if block
    let bucketId = null;
    let bucketSize = null;
    let firstPrice = null;

    if (priceList.length > 0) {
        const firstPriceInfo = priceList[0];
        // Store the first bucket ID, bucket size, and price in variables
        bucketId = firstPriceInfo.bucket_id;
        bucketSize = firstPriceInfo.bucket_size;
        firstPrice = firstPriceInfo.price;

        console.log("First bucket ID:", bucketId);
        console.log("First bucket size:", bucketSize);
        console.log("First price:", firstPrice);
    }

    const loggedin = !!localStorage.getItem('userToken');
    
    if (loggedin) {
        // Extract user details from localStorage
        const userToken = localStorage.getItem("userToken");
        const ompuserId = localStorage.getItem("id");
        const customer_first_name = localStorage.getItem("firstName");
        const customer_last_name = localStorage.getItem("lastName");
        const customer_contact_no = localStorage.getItem("number");
        const customer_email = localStorage.getItem("emailId");

        const itemList = [{
            item_id: productIdtoCart,
            quantity: quantity,
            bucket_id: bucketId
        }];
        
        console.log("Item list for API:", itemList);

        try {
            const response = await fetch("https://ompextension.origa.market/sparesapi/create_order", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    items: itemList,
                    ompuserId,
                    customer_first_name,
                    customer_last_name,
                    customer_contact_no,
                    order_status: "Buy",
                    order_ref_number: "",
                    customer_email,
                    input: {
                        city: "",
                        cityArea: "",
                        companyName: "",
                        country: "IN",
                        countryArea: "",
                        firstName: "",
                        lastName: "",
                        phone: "",
                        postalCode: "",
                        streetAddress1: "",
                        streetAddress2: "",
                    },
                    businessaddress: {
                        city: "",
                        cityArea: "",
                        companyName: "",
                        country: "IN",
                        countryArea: "",
                        firstName: "",
                        lastName: "",
                        phone: "",
                        postalCode: "",
                        streetAddress1: "",
                        streetAddress2: "",
                    },
                    gst_no: "",
                }),
            });

            console.log("Response status:", response.status);  
            const responseData = await response.json(); 
            console.log("Response data:", responseData); 

            const { 
                order_id: orderId, 
                total_quantity: totalQuantity, 
                subtotal: subtotttal, 
                tax_total: gst, 
                discount_total: taxes, 
                shipping_charge: shippingCharges, 
                total 
            } = responseData; // Destructure the needed fields
            
            console.log("Order ID:", orderId);
            console.log("Total Quantity:", totalQuantity);
            console.log("Subtotal:", subtotttal);
            console.log("GST:", gst);
            console.log("Taxes:", taxes);
            console.log("Shipping Charges:", shippingCharges);
            console.log("Total:", total);

            // Navigate to the next page with renamed states
            setLoading(true);
            setTimeout(() => {
                navigate('/manageaddresspart', { 
                    state: { 
                        itemId: productIdtoCart,
                        bucketId, 
                        quantity, 
                         orderId,
                        quantityTotal: totalQuantity,
                        subtotalAmount: subtotttal,
                        gstAmount: gst,
                        taxesAmount: taxes,
                        shippingFee: shippingCharges,
                        totalAmount: total
                    } 
                });
                window.location.reload();
            }, 400);

        } catch (error) {
            console.error("Error adding item to API cart:", error);
        }
    } else {
        // If not logged in, show the login portal
        setLoginPortal(true);
    }
};




      
    const navigate = useNavigate();
    const [filteredHitsToRender, setFilteredHitsToRender] = useState(hits);
    const { onWishlistHandler, heartColor } = useWishListAddOrUpdate();

    const updateFormattedPrice = () => {//is used to update how the price of a product is displayed on a webpage
        setProductDETAILS((prevDetails) => ({//it takes the current details of the product(prevdetails)and creates a new set of details with an updatted formatted price
            ...prevDetails,
            price_details: {
                ...prevDetails.price_details,
                //amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
                //delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
                machine_price: formatCurrency(prevDetails.sales_rate),
            },

        }));
    };
    
    const formatCurrency = (value) => { //like to show in indian rupees it adds commas where needed and shows the right number of decimal place
        const fractionDigits = value % 1 !== 0 ? 2 : 0;
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        }).format(value || 0);
    };

    const findYearDifference = (targetYear) => {
        const currentYear = new Date().getFullYear();
        const yearDifference = currentYear - targetYear;
        return yearDifference;
    }
    const priceConvert = (price) => {
        price = typeof price === 'string' ? price : String(price);


        let count = 1;
        let comma = 3;
        let formatedPrice = ""
        for (let i = price.length - 1; i >= 0; i--) {
            formatedPrice = price[i] + formatedPrice
            if (count === comma) {
                formatedPrice = "," + formatedPrice
                comma = 2;
                count = 0;
            } count++;

        }
        //console.log("==>>", formatedPrice)
        if (formatedPrice[0] === ",") {
            formatedPrice = formatedPrice.slice(1, formatedPrice.length)
        }
        return formatedPrice;



    };
    console.log('filteredHitsToRender---->', filteredHitsToRender);
    useEffect(() => {
        let sortedHits = [...hits];

        if (Sorting !== null) {
            sortedHits = [...hits].sort((a, b) => {
                if (Sorting === "Pricelow_High") {
                    return a["grossPrice"] - b["grossPrice"];
                } else if (Sorting === "PriceHigh_low") {
                    return b["grossPrice"] - a["grossPrice"];
                } else if (Sorting === "AlphabeticallyAtoZ") {
                    return a["productName"].localeCompare(b["productName"]);
                }
                return 0; // Default case
            });
        }

        setFilteredHitsToRender(sortedHits);
    }, [Sorting, hits]);

    const handleFavorite = (index, value) => {
        const updatedHits = [...filteredHitsToRender];
        updatedHits[index]["favorite"] = value;
        setFilteredHitsToRender(updatedHits);
    };

    const onSubmitHandler = (props) => {
        console.log("whish list working", props?.categories.lvl2?.split('>')[2].trim());
        const loggedin = !!localStorage.getItem('userToken');
        if (loggedin) {
            onWishlistHandler(props?.productId, props?.thumbnail, props?.grossPrice, props?.productName, props?.attributes?.Brands, props?.categories?.lvl1?.split('>')[1].trim(), props?.categories.lvl2?.split('>')[2].trim())
        }
    }

    const handleAvailService = (product) => {
        
        const state = {
            group_id: product.group_id,
            objectID: product.objectID || product.id, // Use objectID if available, otherwise fallback to id
            details: product
          };
        
        // if(product?.group_id){
        //     navigate(`/buy/sparetools?group_id=${product.group_id}`,{ state });
        // }
        // else{
        //     navigate(`/buy/sparetools?id=${product.objectID}`,{ state });
        // }

        // console.log(`Redirecting to Group Id :  ${product.group_id} page to ViewSpareProduct  page:  -------> and inside Item Id :  ${product.objectID} `);
        
    
     
        setLoading(true);
        setTimeout(() => {
		 if(product?.group_id){
            navigate(`/buy/sparetools?group_id=${product.group_id}`,{ state });
        }
		 else{
            navigate(`/buy/sparetools?id=${product.objectID}`,{ state });
        }
		
 
          console.log(`Redirecting to ViewSpareProduct page: Group Id: ${product.group_id}, Item Id: ${state.objectID}`);
        }, 1000);
		


        // setLoading(true);
        // setTimeout(() => {
        //   navigate(`/buy/sparetools`, { state });
        //   console.log(`Redirecting to ViewSpareProduct page: Group Id: ${product.group_id}, Item Id: ${state.objectID}`);
        // }, 1000); 
      };
      
    

    const handleModal = async (status, obj) => {
        // Check if the user is logged in
        const loggedin = !!localStorage.getItem('userToken');
        if (!loggedin) {
            // If not logged in, set login portal to true and stop further execution
            setLoginPortal(true);
            return;
        }
    
        // If status is 'phone-modal', handle specific logic
        if (status === 'phone-modal') {
            // Check logged in status again (though redundant as already checked above)
            const loggedin = !!localStorage.getItem('userToken');
            if (!loggedin) {
                // If not logged in, set login portal to true and stop further execution
                setLoginPortal(true);
                return;
            } else {
                // Navigate to a specific route with query parameters
                navigate(`/manageaddresspart`);
            }
        }
    
        // If status is truthy (not 'phone-modal'), show modal
        if (status) {
            setShowModal(status);
        } else {
            // If status is falsy, hide modal and perform additional actions
            setShowModal(false);
            const id = localStorage.getItem('id');
            console.log('obj------------>', obj);
            setBuymachineId(obj?.buymachine_id); // Set buy machine ID from obj parameter
            try {
                // Fetch product details using GraphQL query based on productId
                const { data } = await client.query({ query: GET_SPARE_DETAILS, variables: { itemId: productId } });
                setProductDETAILS(data?.inventoryItem?.response); // Update product details state
                updateFormattedPrice(); // Update formatted price based on new product details
            } catch (error) {
                // Handle errors if GraphQL query fails
                console.error('Error fetching product details:', error);
            }
        }
    }

    const onHidePortal = () => {
        setLoginPortal(false);
    }
    
    console.log("ProductDetails: ",productDETAILS);
    /* console.log("Product Min Price: ",product.minPrice);
    console.log("Product Max Price: ",product.maxPrice); */


    return (
        <>
      {loading && <Loader />}
            {filteredHitsToRender?.length > 0 ? (
                <div className="product-wrap">
                    <div className="products">
                        {filteredHitsToRender?.map((product, index) => (
                            <div className="product" key={index} >
                                <div className="product-inner">
                                    <img className="product-img"  onClick={() => handleAvailService(product)} src={product.thumbnail} alt={product.name} />
                                    <div className="content">
                                    <div className="name">
                                    {product?.brands} <br />
                                        {product?.productName}
                                        
                                            {/* {product?.productName?.length > 18
                                                ? `${product?.productName?.substring(0, 18)}...`
                                                : product?.productName} */}
                                        </div>
                                        <div className="location">
                                            {/* {product?.brands} */}
                                           {/*  {(() => {
                                                const lvl1Category = product?.categories?.lvl1 || '';
                                                const parts = lvl1Category.split(' > ');
                                                const lastPart = parts[parts.length - 1];
                                                return lastPart;
                                                })()} */}
                                                <div className="price-wrap">
                                            <div className="time">{product?.Phase}</div>
                                            
                                             <div className="price">{product?.bucketsize} (Pack of {product?.noofpacks}) <br/> ₹{product?.packprice} (₹{product?.grossPrice}/Ltr)</div>
                                            {/* <div className="categorie"> {product.categories['lvl0']}</div> */}
                                        </div>
                                        </div>

                                        
                                        <button className={`heart ${product?.favorite ? "favorite" : ""}`} type="button" onClick={() => onSubmitHandler(product)}>{heartIcon({ width: 25, onClick: () => handleFavorite(index, !product.favorite) })}</button>
                                         {/* <div className="buttonsinoneline">
                                        <button className="purchasef pbtnsb" key={index} onClick={() => handleAvailService(product)} type="button">Add to Cart</button>
                                        <button className="purchasef pbtnsb1" key={index} onClick={() => handleBuyNow(product.objectID, quantity, product.productName, product.grossPrice)} type="button">Buy Now</button>

                                        </div> */}
                                        <div className="buttonsinoneline">
                                    {deliveryAvailable ? (
                                        <>
                                            {/* <button className="purchasef pbtnsb" onClick={() => handleAvailService(product)} type="button">Add to Cart</button> */}
                                            <button className="purchasef pbtnsb" onClick={() => handleCart(product.objectID, quantity, product.productName,product)} type="button">Add to Cart</button>
                                            <button className="purchasef pbtnsb1" onClick={() => handleBuyNow(product.objectID, quantity, product.productName, product.grossPrice)} type="button">Buy Now</button>
                                        </>
                                    ) : (
                                        <button className="purchasef pbtnsb" onClick={() => handleAvailService(product)}  type="button">Request for Quotation</button>
                                    )}
                                </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <CustomPagination />
                </div>
            ) : (
                <p>No product found.</p>
            )}
 {loginPortal && <LoginModel onHide={onHidePortal} />}
        </>
    );
};

export default connectHits(CustomHits);
